.pulse-btn {
  position: relative;
  min-height: 120px;
  width: 120px;

  .pulse-btn-text {
    position: absolute;
    top: 47px;
    left: 42px;
    color: $dark;
    z-index: 100;
  }

  .pulse-btn-loading {
    position: absolute;
    top: 45px;
    left: 45px;
    color: $dark;
    z-index: 100;

    img{
      max-width: 30px;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  svg circle.center {
    -webkit-transition: r 500ms ease;
    -moz-transition: r 500ms ease;
    -ms-transition: r 500ms ease;
    -o-transition: r 500ms ease;
    transition: r 500ms ease;
  }

  svg circle.pulse {
    -webkit-animation: circles 1s alternate infinite cubic-bezier(.5, 0, .5, 1); /* Safari 4+ */
    -moz-animation: circles 1s alternate infinite cubic-bezier(.5, 0, .5, 1); /* Fx 5+ */
    -o-animation: circles 1s alternate infinite cubic-bezier(.5, 0, .5, 1); /* Opera 12+ */
    animation: circles 1s alternate infinite cubic-bezier(.5, 0, .5, 1); /* IE 10+, Fx 29+ */

    -webkit-transition: r 500ms ease;
    -moz-transition: r 500ms ease;
    -ms-transition: r 500ms ease;
    -o-transition: r 500ms ease;
    transition: r 500ms ease;
  }

  svg circle.pulse:nth-child(4n +1) {
    animation-delay: -0.5s;
  }

  svg circle.pulse:nth-child(4n +2) {
    animation-delay: -1.0s;
  }

  svg circle.pulse:nth-child(4n +3) {
    animation-delay: -1.5s;
  }
}

.btn-link.light-color:hover{
  color: $light;
}

.btn-icon{
  @include transition(opacity .1s);
  min-width: initial;
  border-color: transparent;
  background: none;

  &.active,
  &.activated {
    border-color: transparent;
    background: none;
    box-shadow: none;
    opacity: 0.3;
  }

  .icon:before,
  &.icon:before {
    //font-size: $button-large-icon-size;
  }
}

.btn.no-focus{
  box-shadow: none !important;
}

.btn.auth-disable-color:disabled{
  color: $btn-disabled;

  i {
    color: $btn-disabled;
  }
}

//I guess we need to explicitly override this for bootstrap.  Should probably just set this up properly.
.btn-primary{
  background-color: $primary;
  border-color: $primary;
}
.btn-primary[disabled]{
  background-color: $primary;
  border-color: $primary;
}
.btn-primary:hover{
  background-color: darken($primary, 10%);
  border-color: darken($primary, 10%);
}

.btn-dark{
  background-color: $dark;
  border-color: $dark;
}
.btn-dark[disabled]{
  background-color: $dark;
  border-color: $dark;
}
.btn-dark:hover{
  background-color: darken($dark, 10%);
  border-color: darken($dark, 10%);
}

.btn-link{
  color: $primary;
  //I don't know why this is a problem, but it looks to me like anywhere that we specify the color to
  //be transparent, the react build process turns it into 'initial', which of course breaks everything.
  background-color: $transparent;
}

.btn-signing{
  background-color: $signing-request;
  color: $light;
  border-color: $signing-request;
}

.btn-signing:hover:not([disabled]){
  background-color: darken($signing-request, 10%);
  border-color: darken($signing-request, 10%);
  color: $light;
}

.btn-v1-signing{
  background-color: $signing;
  color : $light;
}
.btn-v1-signing:disabled{
  color : $light;
}
.btn-v1-signing:hover:not([disabled]){
  background-color: $signing-hover;
  color : $light;
}

.btn-v2-signing{
  background-color: $signing-request;
  color : $light;
}
.btn-v2-signing:disabled{
  color : $light;
}
.btn-v2-signing:hover:not([disabled]){
  background-color: darken($signing-request, 10%);
  color : $light;
}

.signing-v1-delete-signature{
  color: $light;
}

.signing-v1-delete-signature:hover:not([disabled]){
  color : darken($light, 10%);
}

.btn:focus{
  box-shadow: none;
  outline: 0;
}

.accordion-sort-btn{
  color: $dark;
  background-color: $row-hover;
}
.accordion-sort-btn:hover{
  cursor: pointer;
  background-color: darken($light, 10%);
}

.btn.disabled, .btn:disabled{
  cursor: default !important;
}
