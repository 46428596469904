.tabs{
  padding-left: 15px;
  //background-color: $workspace-bg-row;

  .tab{

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    color: $lighter-grey;
    .btn-icon{
      color: $lighter-grey;
    }

    display:inline-block;
    font-size: 14px;

    margin-right: 15px;

    .btn-icon{
      font-size: 26px;
    }

    //border-bottom: 1px solid $transparent;

    &:hover{
      //border-bottom: 1px solid $light-grey;
      background-color: $light;
      cursor: pointer;
    }

    &:last-child{
      margin-right: 0px;
    }
  }

  .tab.selected{
    color: $dark;
    background-color: $light;
    //border-bottom: 1px solid $primary;

    .btn-icon{
      color: $primary;
    }
  }
}

.tab-add-btn{
  font-size: 26px;
  padding-top: 2px;
  color : $primary;
}

.tab-add-btn:hover{
  color : darken($primary, 10%) !important;
}

.tab-add-btn:disabled,
.tab-add-btn[disabled]:hover,
.tab-add-btn.disabled,
.tab-add-btn.disabled:hover{
  color: $lighter-grey !important;
}

.account-tabs{
  .nav-link{
    color: $dark;
  }

  .nav-link:hover{
    color: lighten($dark, 20%);
  }

  .nav-link.active{
    color: $primary;
  }
}
