

.vf-header{
  background-color: $light;
  color: $primary;

  line-height: $vf-header-height;
  height: $vf-header-height;

  border-bottom: 1px solid $secondary-text;
}

//This is kind of hacked in here, but this is some fancy flex formatting
//to give a max-width to the workspace name so I can auto-ellipsis it
$home-btn-arrow-width: 55px;
$ws-tile-header-badge-width: 86px;
.header-workspace-name-wrap{
  max-width: calc(100% - #{$ws-tile-header-badge-width}) !important;
}

.header-workspace-wrap{
  max-width: calc(100% - #{$home-btn-arrow-width}) !important;
}
