.signature-request-input{
  border : 1px solid $signing-request;
  background-color: transparentize($signing-request, .7);
  border-radius: 3px;
  color: $signing-request;
}

.signature-request-input:focus{
  border : 1px solid $signing-request;
  background-color: transparentize($signing-request, .7);
  border-radius: 3px;
  color: $signing-request;
  box-shadow: none;
}

.signature-request-input.confirmed{
  border-color: $transparent;
  background-color: $transparent;
  border-radius: 3px;
  color: $dark;
}
