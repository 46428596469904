.auth-info-icon{
  font-size: 25px;

  transition: color 200ms ease-in-out;
}

.auth-info-icon:hover{
  color: $icon-hover-color;
}

.icon{
  vertical-align: top;
}

.icon-top:before{
  vertical-align: top;
}

.ion-paperclip:before {
  transform: rotate(30deg) !important;
}

i.mesg-edit-icon{
  color: $dark;
}

i.mesg-edit-icon:hover{
  color : darken($dark, 10%);
  cursor: pointer;
}

.react-icons-icon{
  vertical-align: text-top;
}
