

.chat-footer-textarea{
  border-color: $light !important;
}

.chat-footer-textarea:hover{
  border-color: $light !important;
}

.chat-footer-textarea:focus{
  outline: 0;
}

.message-block-text{
  width: calc(100% - #{$chat-user-badge-width} - #{$chat-user-badge-width}) !important;
}

.msg-edit-block{
  width: calc(100% - #{$chat-user-badge-width})
}

.msg-edit-doc{
  max-width: calc(100% - #{63px})
}

.msg-edit-doc-delete:hover{
  color: darken($primary, 10%);
}

$upload-preview-width: 50px;
$delete-btn-width: 50px;

.pending-file-upload{
  max-width: calc(100% - #{$upload-preview-width} - #{$delete-btn-width}) !important;
}

$chat-footer-upload-btn-width: 58px;
$chat-footer-send-btn-width: 58px;

.chat-footer-wrap{
  width: calc(100% - #{$chat-footer-upload-btn-width} - #{$chat-footer-send-btn-width}) !important;
}

.max-text-hint-wrap{
  position : absolute;
  top: 1px;
  right : 2px;
  z-index : 100;
  pointer-events: none;
}

.max-text-hint{
  color : $light;
  border-radius : .25rem;
  font-size : 12px;
  padding : 3px 5px;
  opacity : .6;
}
.max-text-hint.dm{
  background-color : $dark;
}
.max-text-hint.thread{
  background-color : $primary;
}
.max-text-hint.past-maximum{
  background-color: $assertive;
}
