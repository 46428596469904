.transition-bg-color{
  transition: background-color 400ms ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg)
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg)
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg)
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes circles {
  from {
    opacity: 0.3;
    stroke-width: 1;
  }
  to {
    opacity: .9;
    stroke-width: 2;
  }
}

//The .fade-* methods are for controlling fade animations using CSSTransitions
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.rotate-90-enter {
  transform: rotate(0deg);
}
.rotate-90-enter-active {
  transform: rotate(90deg);
  transition: transform 400ms ease-in-out;
}
.rotate-90-enter-done{
  transform: rotate(90deg);
}
.rotate-90-exit {
  transform: rotate(90deg);
}
.rotate-90-exit-active {
  transform: rotate(0deg);
  transition: transform 400ms ease-in-out;
}
.rotate-90-exit-done{
  transform: rotate(0deg);
}

.placeholder-shimmer{
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-repeat: no-repeat;
  position: relative;
}
@keyframes placeHolderShimmer{
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.placeholder-shimmer-lg{
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmerLg;
  -webkit-animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-repeat: no-repeat;
  position: relative;
}
@keyframes placeHolderShimmerLg{
  0% {
    background-position: -868px 0;
  }
  100% {
    background-position: 868px 0;
  }
}







.fadeInUp-enter {
  opacity: 0;
}
.fadeInUp-enter-active {
  opacity: 1;

  animation: fadeInUp 150ms linear;
  -webkit-animation: fadeInUp 150ms linear;
}
.fadeInUp-exit {
  opacity: 1;
}
.fadeInUp-exit-active {
  opacity: 0;

  animation: fadeOutDown 150ms linear;
  -webkit-animation: fadeOutDown 150ms linear;
}


@keyframes fadeInUp {
  from {
    transform: translate3d(0,10px,0);
    opacity: 0;
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0,10px,0);
    opacity: 0;
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@keyframes fadeOutDown {
  from {
    transform: translate3d(0,0,0);
    opacity: 1;
  }

  to {
    transform: translate3d(0,10px,0);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutDown {
  from {
    transform: translate3d(0,0,0);
    opacity: 1;
  }

  to {
    transform: translate3d(0,10px,0);
    opacity: 0;
  }
}

