.card-header.clickable{
  cursor: pointer;
  background-color: $light;
}

.card-header.clickable.active{
  background-color: $stable;
}

.card-header.clickable:hover{
  background-color: $stable;
}

.card-border-bottom{
  border-bottom: 1px solid $card-border-color;
}

.workspace-row{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.workspace-row:hover{
  cursor: pointer;
  background-color: $row-hover;

  transition: background-color 150ms;
}

//Partially an Edge hack, this drag/drop styling is somewhat delicate.  Careful or you'll start seeing
//bugs like bug 2563, 2564
.drag-drop-placeholder-wrap{
  background-color: $transparent;

  .icomoon-doc:before{
    background-color: $row-hover;
    border-radius: 8px;
  }
}

.doc-row{

}
.doc-row.active{
  background-color: $row-hover;
}
.doc-row:hover{
  cursor: pointer;
  background-color: $row-hover;
}

.thread-row{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: -5px;
  margin-left: 10px;

  .thread-label {
    font-size: 16px;
    line-height: 16px;
  }
  .thread-preview {
    font-size: 12px;
    line-height: 14px;
  }
}
.thread-row.active{
  background-color: $primary !important;

  p {
    color : $light;
  }
}
.thread-row:hover{
  cursor: pointer;

  background-color: $row-hover;
}

.member-row{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
}
.member-row.active{
  background-color: $dark !important;
  .guest-preview {
    span{
      color : $light !important;
    }
  }
  p {
    color : $light;
  }
}
.member-row:hover{
  cursor: pointer;
  background-color: $row-hover;
}
.member-row-delete-icon:hover{
  cursor: pointer;
}

.guest-row{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
}
.guest-row.active{
  background-color: $dark !important;

  .guest-preview {
    span{
      color : $light !important;
    }
  }
  p {
    color : $light;
  }
}
.guest-row:hover{
  cursor: pointer;

  background-color: $row-hover;
}
.guest-label {
  font-size: 16px;
  line-height: 16px;
}
.guest-preview {
  font-size: 12px;
  line-height: 14px;
}

.doc-preview{
  font-size: 12px;
  line-height: 14px;
}

.item-menu-icon{
  color: $dark;
}

.item-menu-icon:hover{
  color: transparentize($dark, .5);
}

.item-menu-icon.active{
  color: $light;
}

.item-menu-icon.active:hover{
  color: transparentize($light, .5);
}

$notification-icon-width: 30px;
.item-row-wrap{
  word-break: break-word;
}
.guest-row-wrap{
  width: calc(100% - #{$notification-icon-width} - #{10px}) !important;
}
.doc-row-wrap{

}
.thread-row-wrap{

}

.popover-content .list-group-item:last-child{
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.popover-content .list-group-item:hover{
  z-index: initial;
}

.list-group-item:first-of-type{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.sort-row{
  padding-top: 10px;
  padding-bottom: 10px;
  .icon{
    min-width: 15px;
    display: inline-block;
  }
}

.sort-row.active{
  background-color: $primary;
  color : $light !important;
}

.sort-row:hover{
  cursor: pointer;
}

.blocked-row{

  padding-top: 2px;
  font-size: 12px;
  line-height: 14px;

  .blocked-badge{
    background-color : $assertive;
    color : $light;
    border-radius: 3px;
    padding : 1px 2px;
    line-height: 16px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
  }
}
