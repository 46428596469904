$primary:         #3672B3;
$primary-dark-text:#005fa9;
$light:           #FFF;

//I don't know why this is a problem, but it looks to me like anywhere that we specify the color to
//be keyword 'transparent', the react build process turns it into 'initial', which breaks all kinds of styling.
$transparent:     rgba( #fff, 0 );
$dark:            #444;
$black:           #000;
$secondary-text:  #777777 !default;
$stable:          #F8F8F8 !default;
$light-grey:      #B0AEAB !default;
$lighter-grey:    rgb(195, 195, 195) !default;
$row-hover: darken($stable, 3%);
$green:           #0F9D58 !default;
$private-green:   #66CC33 !default;
$notification:    #FF0000 !default;
$assertive:       #ef473a !default;
$workspace-bg-row: #F2F2F2 !default;
$pro-yellow:      #D49B43 !default;

$signing:         #b226fb !default;
$signing-hover:   #921cd0 !default;
$signing-invalid: #db3a4d !default;

$signing-request: #FD6700 !default;

$btn-disabled:    lighten(#cecece, 10%) !default;

$icon-hover-color: #99c7fb !default;

$thread-color-you: #E6EBFA;
$thread-color-them: #EBEBEB;

//Helper for creating color and background color classes from our predefined colors
//Just watch out if you rename them! :)
@mixin color-class($name, $color) {
  .#{$name}-color{
    color: $color !important;
  }
  .#{$name}-bg{
    background-color: $color !important;
  }
  .#{$name}-border{
    border-color: $color !important;
  }
}

@include color-class(primary, $primary);
@include color-class(stable, $stable);
@include color-class(light, $light);
@include color-class(secondary-text, $secondary-text);
@include color-class(transparent, $transparent);
@include color-class(light-grey, $light-grey);
@include color-class(lighter-grey, $lighter-grey);
@include color-class(dark, $dark);
@include color-class(black, $black);
@include color-class(green, $green);
@include color-class(private-green, $private-green);
@include color-class(notification, $notification);
@include color-class(assertive, $assertive);
@include color-class(signing-request, $signing-request);
@include color-class(signing, $signing);
@include color-class(signing-hover, $signing-hover);
@include color-class(signing-invalid, $signing-invalid);
@include color-class(row-hover, $row-hover);
@include color-class(thread-color-you, $thread-color-you);
@include color-class(thread-color-them, $thread-color-them);
@include color-class(pro-yellow, $pro-yellow);
