.react-tiny-popover-container{
  z-index: 2050; //to get on top of modals.
  margin: 10px;
  border-radius: 0.25rem;

  > div {
    -webkit-box-shadow: -5px 5px 5px 0px rgba(153,153,153,0.3);
    -moz-box-shadow: -5px 5px 5px 0px rgba(153,153,153,0.3);
    box-shadow: -5px 5px 5px 0px rgba(153,153,153,0.3);
    border-radius: 0.25rem;
  }
}

//Seeing a weird effect in safari where sometimes a bar of color can get stuck next to this icon.
//Turns out that adding a very small amount of padding maintains the color over whatever kind of weird overdraw was occurring.
//It's possible this could occur in other places I supposed, but for now it's just in this one place, and only on safari.
//I was considering making this platform specific, but it's a very small amount of padding, and I'd rather not introduce
//a (small) purposeful style inconsistency between platforms.  bug 2504
.icon.ion-gear-b{
  padding-left: .1px;
}

//Removes the 'x' from input type=search in both chrome and IE.
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
