.modal{
  display:block;
}

.browser-safari{
  .modal-header{
    min-height: fit-content;
  }
  .modal-footer{
    min-height: 70px;
  }
}

.browser-is-ie11{
  .modal-body{
    max-height: calc(85vh - #{140px}) !important;
  }
}

.modal-content {
  max-height: 85vh;
  overflow-y: auto;
}

.modal-body{
  overflow-y: auto;
}

.modal-backdrop.hide{
  opacity: 0;
  pointer-events: none;
}

.modal-content.pdf-preview{

  .modal-body{
    max-height: 90vh;
    overflow: auto;
  }

  position:relative;

  .modal-header{
    z-index: 10000001;
  }

  .upload-progress-overlay{
    position: absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
    background-color: black;
    opacity: .7;
    z-index: 10000000;

    .spinner{
      margin: 30vh auto auto auto;
      min-height: 50px;
      line-height: 50px;
      width: 80px;
      border-radius: 8px;
      opacity: 1;
      background-color: black;
    }
  }
}

.modal-header{
  border-bottom: none;

  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  flex-direction: row-reverse;

  .modal-title{
    position: relative;
    width: 100%;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  button.close{
    position: absolute;
    top: 16px;
    left : 16px;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;

    opacity: 1;
    text-shadow: none;

    i.icon {
      font-size: 60px;
    }

    //Fix for edge, sometimes the button is invisible there
    z-index: 10000002;
  }

  button:focus{
    box-shadow: none;
    outline: 0;
  }
}

.modal-footer{
  border-top: none;
}

.modal-dialog.modal-xl{
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  margin: 1.75rem;

  .modal-content{
    max-height: 100%;
  }
}

//.primary-modal{
//  .modal{
//    z-index : 1052;
//  }
//  .modal-backdrop{
//    z-index : 1051;
//  }
//}
//
//.secondary-modal{
//  .modal{
//    z-index : 1050;
//  }
//  .modal-backdrop{
//    z-index : 1049;
//  }
//}

.signing-metadata-window{

  padding-left: 5rem;
  padding-right: 5rem;

  p {
    color: $secondary-text;
    margin-bottom: 5px;
    font-size:14px;
  }

  h5{
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.attach-doc-to-thread-window{

  .form-inline{
    label {
      justify-content: flex-end;
      min-width: 100px;
      padding-right: 10px;
    }
    .form-control{
      width: 100%
    }
  }

  .recipients-block{
    label{
      min-width: 100px;
      padding-right: 10px;
    }

    p {
      width: calc(100% - #{100px}) !important;
    }
  }
}
