.fancy-input.form-control{
  box-shadow: none;
}

.fancy-input.form-control.is-invalid{
  box-shadow: none;
  padding-right: .75rem !important;
  background-image: none !important;
}

.auth-card{
  .form-group{
    margin-bottom: 1.5rem;
  }
}

.auth-code-input{
  .ie-11-hack{
    max-width: 45px;
  }
}

.big-round-checkbox{
  .custom-control-label::before,
  .custom-control-label::after {
    top: -3px;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50% !important;
    border: none;
    background-color: $input-group-addon-border-color;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after{
    background-color: $primary !important;
  }
}

.form-control.no-focus:focus{
  border-color: $input-group-addon-border-color;
  box-shadow: none;
}

.switch input:focus + label::before{
  box-shadow: none !important;
}

input[readonly]:focus{
  box-shadow: none;
  outline: 0;
}

textarea.mesg-edit-textarea{
  width: 100%;
  border : none;
  background-color: $transparent;
}

textarea.mesg-edit-textarea:focus{
  background-color: $transparent;
  outline : 0;
  box-shadow: none;
}
.fancy-input-animate-cls{
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.form-control.PhoneInput {
  display: flex;
  align-items: center;

  .PhoneInputInput, .PhoneInputInput:focus {
    outline: 0;
    border-color: $transparent;
  }

  .PhoneInputCountryIcon {
    svg {
      vertical-align: top;
    }
  }
}
