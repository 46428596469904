
//There's a header on this panel, we want it flush with the thread message panel
$attach-docs-header-height: 45px;
.attach-docs-empty-state{

  padding-top: calc(30vh - #{$attach-docs-header-height});
}

.drag-drop-panel{
  -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -ms-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

.pdf-thumbnail-scroll-container{
  background-color: darken($dark, 10%);
  border-right: 1px solid $dark;

  max-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  max-Height: 100%;

  transition: flex 250ms;
  flex: 1;
}

.pdf-thumbnail-scroll-container.not-visible{
  flex: 0;
}

.pdf-thumbnail-scroll-container{
  width: 100%;
}

.pdf-thumbnail{
  img {
    border: 5px solid $transparent;
  }
}

.pdf-thumbnail.active{
  img {
    border: 5px solid $light-grey
  }
}

.pdf-thumbnail.is-editing.active{
  img {
    border: 5px solid $signing;
  }
}
