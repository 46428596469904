html,body {
  height: 100%;
}

html{
  body{
    margin: 0 !important;
  }
}

.celestial-bg::before{
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;

  pointer-events: none;

  background-color: $dark;
  background-image: url('../resources/celestial-bg.png');
  background-repeat: no-repeat;

  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.auth-wrapper{
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  //padding-bottom: 60px;
}

.auth-header-img{
  margin-top: 10vh;
}

.dropzone-wrap{

}

.dropzone-wrap > div {
  outline: 0;
  box-shadow: none;
  height : 100%
}

.center-col{
  height: calc(100vh - #{$vf-header-height}) !important;
}
.center-col.tab-view{
  height: calc(100vh - #{$vf-header-height} - #{$vf-tabview-tab-height}) !important;
}

.click-block{
  position: fixed;
  top : 0;
  left : 0;
  right : 0;
  bottom : 0;

  user-select: none;
  z-index : 0
}

.account-header-popover{
  min-width:200px;
}

.popover-content{
  z-index: 100;
  max-width: 400px;

  a:first-child{
    word-break: break-all;
    white-space: normal;
  }
}

::placeholder{
  color: $light-grey !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $light-grey !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $light-grey !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $light-grey !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: $light-grey !important;
}

.btn-outline-light.to_orange:hover:not([disabled]){
  color: $signing-request;
}
.btn-outline-light.signingv1:hover:not([disabled]){
  color: $signing;
}

//Fix for edge.  If this isn't set, it does this weird thing where you can
//drag the page around
.pdf-page-canvas{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
