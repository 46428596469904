.la_belle_aurore{
  font-family: "La Belle Aurore";
}

.times_new_roman{
  font-family: "Times New Roman";
}

.helvetica{
  font-family: "Helvetica";
}

.courier{
  font-family: "Courier New";
}

.sacramento{
  font-family: "Sacramento-Regular";
}

.cedarville_cursive{
  font-family: "Cedarville-Cursive";
}

.dancing_script{
  font-family: "DancingScript-Regular";
}

.font-pre-load{
  opacity:0;
  height:0;
  width:0;
  display:inline-block;
  position: absolute;
  top: -100px;
  left: -100px;
}
