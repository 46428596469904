.has-pointer{
  cursor: pointer;
}

.default-cursor{
  cursor: default;
}

.has-grabber{
  cursor: grab;
}

.no-pointer{
  pointer-events: none;
}

.auth-link {
  color: $light;
  text-decoration: underline;
}

.auth-link:hover{
  color: darken($light, 20%);
}

.opacity-0{
  opacity: 0;
}

.invisible{
  visibility: hidden;
}

.height-100{
  height: 100%;
}

//Kind of delicate in here.  find a way to refactor this into the component.
.column-scroll-layout{
  height: 100vh;
  overflow:hidden;

  .container-fluid {
    height: 100%;

    .row {
      height: 100%;

      .col, .col-3, .col-4, .col-6 {
        height: 100%;

        .scrollable-col {
          height: 100%;

          overflow-y: auto;
        }
        .scrollable-col.has-sticky-header{
          padding-bottom: 60px;
        }
      }
      .col, .col-3 > div, .col-4 > div, .col-6 > div{
        height: 100%;
      }
    }
  }
}

.scrollable-col{
  overflow-x: hidden;
}

.column-scroll-layout.has-header{
  padding-top: $vf-header-height;
}

textarea.no-resize{
  resize: none;
}

.hover-underline:hover{
  text-decoration: underline !important;
}

.auto-ellipsis{
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.settings-menu-item{
  background-color: $light;
  color: $dark;
}

.settings-menu-item:hover{
  background-color: darken($light, 10%);
}

.word-break-all{
  word-break: break-all;
}

//This is a very, very poor hack.  Right now we're not showing images in any of our print previews,
//if that changes this will break.  There's a problem on Edge, where somehow some random images show up
//in pdf print preview.  Some of them are associated with the logos in Header.js somehow.
//I can VERIFY that I'm not sending any of this to print preview, so I'm not sure where it comes from.
@media print {
  .allow-preview-imgs.browser-edge {
    img {
      display: none;
    }
  }
  :not(.allow-preview-imgs) {
    img {
      display: none;
    }
  }
  .allow-preview-imgs:not(.browser-edge) {
    img {
      display: inline;
    }
  }
}
