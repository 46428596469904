//These values come from just measuring the elements.  Probably a cleaner way to do this.
$accordion-badge-width: 21.5px;
$accordion-btn-width: 55px;

.accordion-header{
  background-color: $light;

  -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -ms-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.accordion-header.locked{
  background-color: $light;
  border-bottom: 1px solid $lighter-grey;
  color: $dark;
  z-index: 10;
}

.accordion-header.locked:hover{
  background-color: darken($stable, 20%);
  cursor: pointer;
}

.tab-view-header{
  background-color: $light;

  -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -ms-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.tab-view-header.locked{
  z-index: 10;
}

.tab-view-header.locked:hover{
  cursor: pointer;
}

.accordion-title-host{
  max-width: calc(100% - #{$accordion-badge-width} - #{$accordion-btn-width}) !important;
}

.accordion-title-guest{
  max-width: calc(100% - #{$accordion-badge-width}) !important;
}

.home-accordion-header{
  padding-top: 5px;
  height: 51px;
  line-height: 38px;
  cursor: pointer;

  //ugly hack! this icon is next to the workspace icon, and they're different sizes
  //which is causing the notification icon to show up in the wrong spot.
  //Just hacking this for now.
  .ion-android-person{
    margin-top:-1px !important;
  }
}

.ws-accordion-header{
  line-height: 34px;
  height: 48px;
  padding-top: 7px;

  i {
    margin-top: 0px !important;
  }

  button {
    margin-top: -5px !important;
  }
}
