@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.react-rectangle-selection {
  background-color: transparentize($signing-request, .7);
  border: 1px solid $signing-request;
  position: absolute;
  animation-duration: 0.2s;
  animation-name: fadeIn;
  user-select: none;
}

.react-rectangle-selection--fadeout {
  animation-duration: 0.3s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}